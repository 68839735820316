@import "../../global/styles/partials.scss";

.page {
  background: #16181e;
  min-height: 100vh;
}

.content {
  display: flex;
  justify-content: space-between;
  padding: 28px 60px;
}

.video {
  max-width: 1082px;
  width: 100%;
}

.videoBlock {
  background: linear-gradient(180deg, #1f2630 0%, #1a2028 100%);
  box-shadow: 15px 30px 40px rgba(0, 0, 0, 0.746614);
  border-radius: 5px;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.videoTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: $white;
  margin-bottom: 15px;
  margin-top: 40px;
}
.videoDescription {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: $gray;
}

.vidget {
  max-width: 340px;
  width: 100%;
  margin-left: 60px;
}

.nextVideo {
  position: relative;
  text-align: right;
  display: flex;
  max-width: 117px;
  width: 100%;
  margin-left: auto;
  margin-top: 40px;
  &:hover .nextTip {
    pointer-events: all;
    opacity: 1;
  }
}

.nextButton {
  background: linear-gradient(180deg, #2c3743 0%, #1b242d 100%);
  box-shadow: -1px -2px 10px #313a45, 1px 2px 10px #000000;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: $gray;
  display: inline-block;
  padding: 14px 25px;
  cursor: pointer;
  width: 100%;
}

.nextTip {
  box-shadow: 15px 30px 40px rgba(0, 0, 0, 0.746614);
  border-radius: 5px;
  background: $blue;
  padding: 10px 15px;
  min-width: 183px;
  position: absolute;
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  line-height: 18px;
  pointer-events: none;
  opacity: 0;
  transition: all 0.4s ease-in;
  p {
    position: relative;
    z-index: 2;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: calc(50% - 9px);
    background: $blue;
    width: 18px;
    height: 18px;
    transform: rotate(-45deg);
    z-index: 1;
  }
}
