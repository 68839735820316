@import "./partials/fonts";
@import "./partials/colors";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: $white;
}

button {
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

a {
  &.active {
    font-weight: bold;
  }
}

textarea,
input {
  font-family: "Montserrat", sans-serif;
  &::placeholder {
    font-family: "Montserrat", sans-serif;
  }
}

p,
h1,
h2,
h3,
h4,
hr {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

img {
  max-width: 100%;
  display: inline-block;
}

ul {
  margin: 0;
  padding: 0;
}

.container {
  padding-left: 30px;
  padding-right: 30px;
  @media screen and (max-width: 1230px) {
    padding: 0 15px;
  }
}

.vimeo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &-image {
    background-size: cover;
  }
}

.vimeo-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
