
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Montserrat/Montserrat-Medium.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/Montserrat/Montserrat-Medium.eot') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Montserrat/Montserrat-Medium.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/Montserrat/Montserrat-Medium.svg') format('svg'); /* Legacy iOS */
}